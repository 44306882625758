<template>
  <div>
    <SEARCH :list="searchArr"></SEARCH>
    <TABS :list="tabsArr"></TABS>
    <TABLE :list="tableArr" :data="dataArr"></TABLE>
    <PAGE :count="count"></PAGE>
  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import { getId } from '@/api/project'
export default {
  name: 'IdManage',
  components: {
    SEARCH,
    TABS,
    TABLE,
    PAGE,
  },
  data() {
    return {
      searchArr: [
        // {
        //   name: '姓名',
        //   type: 'input',
        //   options: [],
        //   key: 'name',
        // },
        // {
        //   name: '所属单位',
        //   type: 'picker',
        //   options: [],
        //   key: 'projectUnit',
        // },
        {
          name: '证件状态',
          type: 'picker',
          options: [],
          key: 'idState',
        },
        // {
        //   name: '承包单位',
        //   type: 'picker',
        //   options: [],
        //   key: 'bUnit',
        // },
        // {
        //   name: '承办单位',
        //   type: 'picker',
        //   options: [],
        //   key: 'dUnit',
        // },
        {
          name: '工作证类型',
          type: 'picker',
          options: [],
          key: 'workType',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '新增工程',
          func: '',
        },
        {
          type: 'primary',
          name: '导入工程',
          func: '',
        },
        {
          type: 'primary',
          name: '下载模板',
          func: '',
        },
        {
          type: 'primary',
          name: '导出工程',
          func: '',
        },
        {
          type: 'danger',
          name: '批量删除',
          func: '',
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'user.photo',
          label: '头像',
          width: '130',
        },
        {
          prop: 'user.nickname',
          label: '姓名',
          width: '130',
        },
        {
          prop: 'start_time',
          label: '身份证',
          width: '130',
        },
        {
          prop: 'start_time',
          label: '所属工种',
          width: '130',
        },
        {
          prop: 'start_time',
          label: '承包单位',
          width: '130',
        },
        {
          prop: 'start_time',
          label: '承办单位',
          width: '130',
        },
        {
          prop: 'start_time',
          label: '证件有效期',
          width: '200',
        },
        {
          prop: 'start_time',
          label: '工作证类型',
          width: '140',
        },
        {
          prop: 'start_time',
          label: '证件状态',
          width: '140',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          but: true,
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '编辑',
          func: '',
        },
        {
          type: 'danger',
          name: '删除',
          func: '',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        type: '',
        name: '',
        unit_id: '',
        create_at: '',
      },
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.formData.unit_id = this.$store.getters.USER_ID
      getId(this.formData).then((res) => {
        console.log(res, '这是证件管理')
        const { result } = res

        this.count = result.count
        result.data.forEach(item => {
          item.buttons = this.butnArr
        })
        this.dataArr = result.data
      })
    },
  },
}
</script>

<style lang="stylus" scoped></style>
