import { render, staticRenderFns } from "./IdManage.vue?vue&type=template&id=666c156a&scoped=true&"
import script from "./IdManage.vue?vue&type=script&lang=js&"
export * from "./IdManage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "666c156a",
  null
  
)

export default component.exports